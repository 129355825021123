.review-panel {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 250px;
  width: 100%;
  background-image: url("../../assets/images/review-panel-back-gradient.png");
  background-repeat: repeat-x;
  background-size: contain;
}

.review-image {
  pointer-events: auto;
  right: 0;
  height: 120px;
  position: absolute;
  margin: 15px;
}
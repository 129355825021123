* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  //background-color: #efefef;
  background: none transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
  sans-serif;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.App {
  height: 100%;
  text-align: center;
}

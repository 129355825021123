.watermark {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/watermark.png');
  background-position: center;
  background-repeat: repeat;
  background-size: 256px;
}
.playback-controls {
  width: 100%;
  position: absolute;
  background-color: rgba(127, 127, 127, 0.3);
  bottom: 0;
  text-align: left;

  .playback-controls-button {
    margin: 7px 9px;
  }

  .playback-controls-slider {
    padding: 0 24px 0 6px;
  }

  .playback-controls-item {
    margin: 0 10px;
  }
}

.cover-play-button-circle {
  position: absolute;
  top: calc(50vh - 8vmin);
  left: calc(50vw - 8vmin);
  height: 16vmin;
  width: 16vmin;
  background: rgba(1, 73, 104, 0.8);
  align-items: center;
  border-radius: 50%;

  .cover-play-button {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16vmin;
  }
}
